import React from 'react';

import { Box } from '@chakra-ui/react';
import './loader.css';

export const PageLoader: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        h: '100vh',
        w: '100%',
        background: 'absoluteDark',
        opacity: '0.7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 996,
      }}
    >
      <div className='lds-roller'>
        <div></div>
      </div>
    </Box>
  );
};
